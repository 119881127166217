import * as React from 'react';
import styles from './HomePage.module.css';
import { Link } from 'react-router-dom';
import logo from '../../assets/FloatingSage-Orange-logo2-only-name-transparent.png'; // Path to the logo image (2000x474 dimension transparent logo)
const gifImage = require('../../assets/floating-sage-icon.gif'); // Updated import for the GIF image

const HomePage: React.FC = () => {
  return (
    <div className={styles.container}>
      {/* Header Section */}
      <header className={styles.header}>
        <div className={styles.navBar}>
          <img src={logo} alt="Monolith AI Logo" className={styles.logo} />
          <div className={styles.navLinks}>
            <Link to="/contact" className={styles.link}>Contact</Link>
            <a href="https://monolithai.me/" className={styles.button}>Login/Register</a>
          </div>
        </div>
      </header>

      {/* Main Content Wrapper */}
      <div className={styles.mainWrapper}>
        {/* GIF Section */}
        <div className={styles.gifSection}>
          <img src={gifImage} alt="Intro Animation" className={styles.gif} />
        </div>

        {/* Hero Section */}
        <div className={styles.heroSection}>
          <div className={styles.heroContentCompact}> {/* Updated class to adjust spacing */}
            <h1 className={styles.mainHeading}>Unlock the Power of Your Data</h1>
            <p className={styles.subText}>Transform how you interact with your company's documentation with Monolith AI.</p>
            <div className={`${styles.ctaButtons} ${styles.centeredButtons}`}>
              <a href="https://monolithai.me/" className={styles.primaryButton}>Try the Demo</a>
              <Link to="/contact" className={styles.secondaryButton}>Get Started</Link>
            </div>
          </div>
        </div>

        {/* Content Sections */}
        <div className={styles.mainContent}>
          {/* Why Monolith AI Section */}
          <section className={styles.section}>
            <div className={styles.sectionContent}>
              <h2 className={styles.sectionHeading}>Your Data, Simplified</h2>
              <p className={styles.sectionText}>Monolith AI’s advanced Chat Bot revolutionizes the way you explore your product documentation. Ask questions, and get instant, precise, and contextual answers tailored to your company's needs. Whether you're searching for technical details or operational insights, our AI ensures you get what you need without endless searching.</p>
            </div>
          </section>

          {/* How It Works Section */}
          <section className={styles.sectionAlt}>
            <div className={styles.sectionContent}>
              <h2 className={styles.sectionHeading}>Ask. Retrieve. Respond.</h2>
              <p className={styles.sectionText}>Harness the power of Monolith AI’s deep-learning models to interact with your data in real time. Upload your documents, and our Chat Bot will be ready to provide detailed responses to your queries, saving your team time and boosting productivity.</p>
            </div>
          </section>

          {/* Tailored to Your Business Section */}
          <section className={styles.section}>
            <div className={styles.sectionContent}>
              <h2 className={styles.sectionHeading}>A Solution Built for Your Documentation</h2>
              <p className={styles.sectionText}>Monolith AI’s flexible AI-driven Chat Bot can be customized to adapt to the specific structure and language of your product documentation. Whether you need it for technical manuals, service guides, or business reports, we fine-tune our bot to ensure it fits your workflow seamlessly.</p>
            </div>
          </section>

          {/* Boost Efficiency Section */}
          <section className={styles.sectionAlt}>
            <div className={styles.sectionContent}>
              <h2 className={styles.sectionHeading}>Maximize Your Team’s Time</h2>
              <p className={styles.sectionText}>Reduce manual searching and let our AI handle document exploration. With our Chat Bot, your team can focus on decision-making and problem-solving, not data digging. Let us do the heavy lifting.</p>
            </div>
          </section>

          {/* Start Your Journey Section */}
          <section className={styles.section}>
            <div className={styles.sectionContent}>
              <h2 className={styles.sectionHeading}>Take Control of Your Data Today</h2>
              <p className={styles.sectionText}>Experience the efficiency and precision that only Monolith AI can offer. Get started now and see how AI can transform the way you work with your documentation.</p>
              <div className={`${styles.ctaButtons} ${styles.centeredButtons}`}>
                <Link to="/contact" className={styles.primaryButton}>Get Started</Link>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* Footer Section */}
      <footer className={styles.footer}>
        <div className={styles.footerContent}>
          <p>&copy; 2024 Monolith AI | Privacy Policy | Terms of Service</p>
          <p>Contact: <a href="mailto:neha.yadav@monolithai.in" className={styles.footerLink}>neha.yadav@monolithai.in</a></p>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
